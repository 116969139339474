<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span>Download</span>
        <v-btn text icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Download or share a zip archive of selected content. Links are valid
          for 7 days.
        </p>
        <profile-picker
          v-model="profileId"
          :disabled="isLoading"
        />
        <period-picker
          v-model="period"
          @computed="computedPeriod = $event"
          :disabled="isLoading"
        />
      </v-card-text>
      <v-card-actions>
        <template v-if="isLoading">
          <v-spacer />
          <p class="grey--text mb-1">
            <v-progress-circular
              indeterminate
              width="2"
              size="16"
              class="mr-2"
              color="grey"
            />
            Creating archive. This may take several minutes.
          </p>
        </template>
        <template v-else-if="error">
          <v-spacer />
          <p class="error--text mb-1">
            An error occurred<template v-if="error.response">: {{error.response.data.detail}}</template>
          </p>
        </template>
        <template v-else-if="archive">
          <v-text-field
            ref="link"
            readonly
            outlined
            dense
            hide-details
            label="Archive link"
            :value="link"
            class="mr-2"
            @focus="focusLink"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="copyLink">
                    mdi-content-copy
                  </v-icon>
                </template>
                <span v-if="!linkCopied">Copy link</span>
                <span v-else>Copied!</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <v-btn text color="primary" :href="link" target="_blank">
            <v-icon left>mdi-download</v-icon>
            Download
          </v-btn>
        </template>
        <template v-else>
          <v-spacer />
          <v-btn
            text
            color="primary"
            :disabled="!profileId || !computedPeriod"
            @click="create"
            >Create Archive</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfilePicker from './ProfilePicker.vue';
import PeriodPicker from './PeriodPicker.vue';
import dayjs from 'dayjs';
import axios from 'axios';

export default {
  name: "ArchiveDialog",
  components: {ProfilePicker, PeriodPicker},

  data() {
    return {
      showDialog: false,
      profileId: null,
      period: null,
      computedPeriod: null,
      isLoading: false,
      archive: null,
      linkCopied: false,
      error: null,
      cancelToken: null,
      waitTimer: null
    }
  },

  computed: {
    link() {
      if (!this.archive)
        return null;
      return this.$apiBaseURL + '/archives/' + this.archive.id + ':download';
    }
  },

  methods: {
    open(profileId, period) {
      this.profileId = profileId;
      this.period = period;
      this.archive = null;
      this.isLoading = false;
      this.error = null;
      this.showDialog = true;
    },

    close() {
      this.showDialog = false;
      if (this.cancelToken !== null) {
        this.cancelToken.cancel();
      }
      clearTimeout(this.waitTimer);
    },

    async create() {
      this.isLoading = true;
      this.cancelToken = axios.CancelToken.source();
      try {
        let response = await this.$api.post('/archives', {
          profile_id: this.profileId,
          expire_in: 'P7D',
          start_time: dayjs(this.computedPeriod.start).startOf('day').toISOString(),
          end_time: dayjs(this.computedPeriod.end).add(1, 'day').startOf('day').toISOString()
        }, {
          cancelToken: this.cancelToken.token
        })

        this.archive = response.data;
        this.waitUntilComplete();

      } catch(err) {
        if (axios.isCancel(err))
          return;

        this.error = err;
        this.isLoading = false;
      }
    },

    waitUntilComplete() {
      let delay = 2000;

      let callback = async () => {
        try {
          let response = await this.$api.get('/archives/' + this.archive.id, {
            cancelToken: this.cancelToken.token
          });

          this.archive = response.data;
          if (this.archive.status === 'complete') {
            this.isLoading = false;
            return;
          } else if (this.archive.status === 'failed') {
            this.error = {};
            this.isLoading = false;
            return;
          }
        } catch (err) {
          if (axios.isCancel(err))
            return;

          if (err.response) {
            this.error = err;
            this.isLoading = false;
            return;
          }
        }

        delay = Math.min(delay * 2, 16000);
        this.waitTimer = setTimeout(callback, delay);
      }

      this.waitTimer = setTimeout(callback, delay);
    },

    focusLink(event) {
      setTimeout(() => {
        event.target.select();
        event.target.setSelectionRange(0, 99999)
      }, 50);
    },

    copyLink() {
      let input = this.$refs.link.$el.querySelector('input');
      input.select();
      input.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(this.link);
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 1000);
    }
  },

  watch: {
    profileId() {
      this.error = null;
      this.archive = null;
    },

    period() {
      this.error = null;
      this.archive = null;
    }
  }
};
</script>