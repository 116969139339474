<template>
  <img v-if="deliverable && deliverable.thumbnail" :src="deliverable.thumbnail.url" class="thumbnail" :style="{maxWidth: maxWidth + 'px', maxHeight: maxHeight + 'px'}" />
  <v-responsive v-else :aspect-ratio="1.5" :max-width="brokenMaxWidth">
    <div class="fill-y d-flex align-center justify-center grey lighten-3">
      <v-icon>mdi-image-broken-variant</v-icon>
    </div>
  </v-responsive>
</template>

<script>
export default {
  name: "DeliverableThumbnail",
  props: {
    deliverable: Object,
    maxHeight: Number,
    maxWidth: Number
  },

  computed: {
    brokenMaxWidth() {
      if (this.maxHeight) {
        let maxWidth = this.maxHeight * 1.5;
        return this.maxWidth ? Math.min(maxWidth, this.maxWidth) : maxWidth;
      }
      return this.maxWidth;
    }
  }
}
</script>

<style scoped>
.thumbnail {
  max-width: 100%;
}
</style>