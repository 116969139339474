<template>
  <v-tooltip bottom :color="color" :disabled="!_metric || !_metric.message">
    <template v-slot:activator="{ on, attrs }">
      <div v-if="isUsage" class="ma-1">
        <v-progress-linear
          :value="_metric && _metric.value * 100"
          :color="color || 'grey'" height="20"
          v-bind="attrs"
          v-on="on"
          >
          {{label}}
          <template v-if="_metric.value != null">: {{Math.round(_metric.value * 100)}}%</template>
          <template v-else>: Unknown</template>
        </v-progress-linear>
      </div>
      <v-chip v-else :color="color" class="ma-1" v-bind="attrs" v-on="on">
        <v-icon left>{{icon}}</v-icon>
        {{label}}<template v-if="_metric && _metric.value">: {{_metric.value}}</template>
      </v-chip>
    </template>
    <span v-if="_metric">{{_metric.message}}</span>
  </v-tooltip>
</template>

<script>
import {convertLegacyMetric} from "../utils";

export default {
  props: {
    descriptor: Object,
    metric: Object
  },

  computed: {
    _metric() {
      // convert legacy metrics if needed - can eventually be phased out
      return convertLegacyMetric(this.metric);
    },

    isUsage() {
      return ["system.memory-usage", "system.disk-usage", "printer.supply-level"].includes(this.descriptor.type);
    },

    color() {
      if (!this._metric)
        return null;

      return {
        ok: "success",
        warning: "warning",
        error: "error"
      }[this._metric.status] || "info";
    },

    icon() {
      return {
        "success": "mdi-check-circle",
        "warning": "mdi-alert-circle",
        "error": "mdi-alert-circle",
        "info": "mdi-information"
      }[this.color] || "mdi-help-circle";
    },

    label() {
      if (this.descriptor.name)
        return this.descriptor.name;
        
      if (this.isUsage) {
        return {
          "system.memory-usage": "Memory",
          "system.disk-usage": "Disk"
        }[this.descriptor.type];
      }

      return this.descriptor.id;
    }
  }
}
</script>
