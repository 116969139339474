export class Filter {
  constructor(id, items, value = null, enabled = false, aggregatesType = null, aggregatesMode = 'one') {
    this.id = id;
    this.items = items;
    this.value = value !== null ? value : items[0];
    this.enabled = enabled;
    this.aggregatesType = aggregatesType;
    this.aggregatesMode = aggregatesMode;
  }

  clone() {
    return new Filter(this.id, this.items, this.value, this.enabled, this.aggregatesType, this.aggregatesMode);
  }
}

export default class Filters extends Array {
  constructor(...items) {
    super(...items);
    makeReactive(this); // seems to be needed for Vue 2 to support array extensions
  }

  static fromSchema(schema) {
    let filters = [];
    
    for (let attr of schema.attributes) {
      if (attr.enum.length) {
        filters.push(new Filter(attr.id, attr.enum));
      } else if (attr.type == "boolean") {
        filters.push(new Filter(attr.id, [true, false]));
      }
    }

    return new Filters(...filters);
  }

  get enabled() {
    return this.filter(filter => filter.enabled);
  }

  apply(items) {
    let enabledFilters = this.enabled;

    if (!enabledFilters.length)
      return items;

    return items.filter(item => {
      for (let filter of enabledFilters) {
        if (item.attributes[filter.id] !== filter.value)
          return false;
      }
      return true;
    })
  }

  clone() {
    return this.map(filter => filter.clone());
  }
}


function makeReactive (obj) {
  // eslint-disable-next-line no-proto
  const proto = obj.__proto__

  Object.defineProperty(obj, '__proto__', {
    get () { return proto },
    // eslint-disable-next-line no-proto
    set (newValue) { proto.__proto__ = newValue }
  })
}