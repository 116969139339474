<template>
  <div v-if="value && value.length">
    <p class="text--secondary">Only include records where...</p>
    <v-row v-for="(filter, index) in value" :key="index" no-gutters dense align="center">
      <v-checkbox
        :input-value="filter.enabled"
        @change="update(index, 'enabled', $event)"
        hide-details
        :disabled="disabled"
        class="shrink mt-0 mb-1"
      ></v-checkbox>
      <v-subheader class="pl-2" :class="{disabled: !filter.enabled || disabled}">
        <b class="mr-2">{{filter.id}}</b> is
      </v-subheader>
      <v-select
        :value="filter.value"
        @input="update(index, 'value', $event)"
        :items="filter.items"
        hide-details
        dense
        :disabled="!filter.enabled || disabled"
        class="shrink mt-0 mb-1"
      ></v-select>
      <v-select
        v-if="filter.aggregatesType"
        :value="filter.aggregatesMode"
        @input="update(index, 'aggregatesMode', $event)"
        :items="aggregatesModeItems"
        hide-details
        dense
        :disabled="!filter.enabled || disabled"
        class="shrink mt-0 mb-1 ml-2"
      ></v-select>
    </v-row>
  </div>
  <p v-else class="grey--text">No filters available</p>
</template>

<script>
import Filters from "../filters"

export default {
  name: "FiltersList",
  props: {
    value: Filters,
    disabled: Boolean
  },

  computed: {
    aggregatesModeItems() {
      return [
        {
          value: 'one',
          text: 'in at least one linked record'
        },
        {
          value: 'all',
          text: 'in every linked record'
        }
      ]
    }
  },

  methods: {
    update(index, prop, value) {
      let filters = this.value.clone();
      filters[index][prop] = value;
      this.$emit('input', filters);
    }
  }
}
</script>

<style scoped>
.disabled {
  opacity: 0.5;
}
</style>